import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import App from "./components/App";
import Callback from "./components/Callback";

export default function Root(props) {
  const { auth0, userState } = props;

  return (
    <BrowserRouter forceRefresh={true}>
      <Switch>
        <Route path="/login">
          <App auth0={auth0} userState={userState} />
        </Route>
        <Route path="/callback">
          <Callback auth0={auth0} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
