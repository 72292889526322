import React, { useState } from "react";
import { Redirect } from "react-router-dom";

export default function Callback(props) {
  const { auth0 } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [callbackManaged, setCallbackManaged] = useState(false);
  const [appState, setAppState] = useState(null);

  const handleRedirectCallback = async () => {
    try {
      const redirectResponse = await auth0.handleRedirectCallback();
      setAppState(redirectResponse.appState);
      setCallbackManaged(true);
    } catch (e) {
      console.error(e);
    }
  };

  if (isLoading) {
    handleRedirectCallback();
    setIsLoading(false);
  }

  if (isLoading || !callbackManaged) {
    return (
      <div class="d-flex justify-content-center my-5">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (callbackManaged) {
    return <Redirect push to={appState.targetUrl} />;
  }
}
