import { observer } from "mobx-react-lite";
import React from "react";
import { Redirect, useLocation } from "react-router-dom";

import useAuthentication from "../../../../hooks/useAuthentication";

import "../scss/App.scoped.scss";
import logo from "../static/inReach_logo.svg";

function App(props) {
  const { auth0, userState } = props;

  const { AuthenticationLoader } = useAuthentication(auth0, userState);
  const location = useLocation();

  const targetUrl = location?.state?.targetUrl || "/";

  if (userState.isLoading) {
    return <AuthenticationLoader />;
  } else {
    return !userState.isAuthenticated ? (
      <div class="d-flex p-5 justify-content-center h-100">
        <div class="d-flex justify-content-center flex-column p-5 align-items-center">
          <img src={logo} alt="" width="50" height="50" />
          <h1 class="mb-5">DIG</h1>
          <button
            id="auth-login-button"
            class="btn btn-lg btn-outline-primary"
            onClick={() =>
              auth0.loginWithRedirect({
                appState: { targetUrl: location.state?.targetUrl || "/" },
              })
            }
          >
            Log In
          </button>
        </div>
      </div>
    ) : (
      <Redirect push to={targetUrl || "/"} />
    );
  }
}

export default observer(App);
